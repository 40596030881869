import styled from "styled-components";
import { Link as Rota } from "react-router-dom";

export const Indaituba = styled.div`
  img {
    width: 360px;
    height: 80px;
    margin-top: 30px;
    @media (max-width: 920px) {
      width: 315px;
      height: 80px;
    }

    @media (max-width: 660px) {
      margin-top: 35px;
      width: 210px;
      height: 50px;
    }
  }
`;

export const Fiec = styled.div`
  img {
    width: 311px;
    height: 173px;

    @media (max-width: 920px) {
      margin-top: 20px;
      width: 200px;
      height: 115px;
    }

    @media (max-width: 660px) {
      width: 142px;
      height: 85px;
    }
  }
`;
export const Logos = styled.div`
  display: flex;
  margin-top: 5vh;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 420px) {
    margin-top: 0;
    flex-direction: column;
    justify-content: none;
    align-items: center;
  }
`;

export const Titulo = styled.h1`
  text-align: center;
  margin-top: 5vh;
  font-weight: bolder;
`;

export const P = styled.p`
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
`;

export const ConfirmCadastro = styled.div`
  padding-top: 30px;
  text-align: center;
  width: 100%;
  cursor: pointer;

  h1 {
    color: red;
    font-size: 50px;

    @media (max-width: 500px) {
      font-size: 30px;
      padding: 0 5px;
    }
  }

  @media (max-width: 420px) {
    padding-top: 10px;
  }
`;

export const Link = styled(Rota)`
  text-decoration: underline;
  color: red;
  text-align: center;
  font-size: 30px;
  font-weight: bolder;

  a:link,
  a:visited,
  a:active {
    text-decoration: none;
  }
  a:hover {
    color: red;
    font-size: 120%;
  }

  &:hover {
    color: red;
  }
`;
export const Doador = styled.div`
  margin-top: 2%;
  width: 98.5%;
  text-align: center;

  @media (max-width: 775px) {
    display: flex;
    justify-content: space-between;
  }

  a {
    font-size: 20px;
    cursor: pointer;
    padding: 0px 0px 0px 45px;
    margin-left: -5px;

    @media (max-width: 775px) {
      padding: 0px 0px 0px 20px;
      margin-left: 0px;
    }
  }
`;

export const ContainerWhatsApp = styled.div`
  width: 100%;
  height: 150px;

  margin: 10px 0px 0px 0px;
  padding: 0 10px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 15px;

  p {
    height: fit-content;
    margin: auto 0;

    max-width: 250px;
    font-size: 1rem;
    font-weight: bold;
    text-align: justify;
  }

  img {
    height: 100%;
  }

  @media (max-width: 420px) {
    height: 100px;
    font-size: 0.85rem;
    line-height: 1.125rem;
  }
`;
