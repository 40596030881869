import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Register from "./Pages/Register";
import Home from "./Pages/Home";
import HomeClosed from "./Pages/HomeClosed";
import Info from "./Pages/Info";
import Login from "./Pages/Login";
import Agendas from "./Pages/Agendas";
import Contato from "./Pages/Contato";
import ConfirmData from "./Pages/ConfirmData";
import ConfirmDoacao from "./Pages/ConfirmDoacao";
import Doadores from "./Pages/Doadores/index";
import AtualizarCadastro from "./Pages/AtualizarCadastro";
import PdfDoadores from "./Pages/PdfDoadores";
import Admin from "./Pages/Admin";
import ChatBot from "./Pages/ChatBot";
import { isAuthenticated, isAuthenticatedAdmin } from "../src/auth";
import api from "./services/api";
import Graficos from "./Pages/Graficos";
import HomeTemporaria from "./Pages/HomeTemporaria";
import Avisos from "./Pages/Avisos";
import MinhasDoacoes from "./Pages/MinhasDoacoes";
import MeuAgendamento from "./Pages/MeuAgendamento";
import MeuGrupoDoadores from "./Pages/MeuGrupoDoadores";
import Cancelamentos from "./Pages/Cancelamentos";
import TodosGruposDoadores from "./Pages/TodosGruposDoadores";
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";

export const USER_ADMINISTRADOR = 1;
export const USER_DOADOR = 2;
export const USER_PUBLIC = 3;

export default function App() {
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    api
      .get(`/calendarscheck`)
      .then((response) => {
        if (response.data.success) {
          setClosed(false);
        } else {
          setClosed(true);
        }
      })
      .catch((error) => {
        setClosed(true);
      });
  }, []);

  /**
   * Rota para qualquer pessoa
   */
  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated() || isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  /**
   * Rota para usuários não administradores, porém logados
   */
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  /**
   * Rota para usuários administradores logados
   */
  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedAdmin() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  return (
    <BrowserRouter>
      <Header />
      <Sidebar />
      <Switch>
        {/* Rotas públicas */}
        {closed ? (
          <PublicRoute exact path="/Home" component={HomeClosed} />
        ) : (
          <PublicRoute exact path="/Home" component={Home} />
        )}
        <PublicRoute exact path="/" component={HomeTemporaria} />
        <PublicRoute exact path="/Login" component={Login} />
        <PublicRoute exact path="/Register" component={Register} />
        <PublicRoute exact path="/Contato" component={Contato} />
        <PublicRoute exact path="/Info" component={Info} />
        <PublicRoute
          exact
          path="/Ajuda"
          component={() => {
            window.location.href = "https://doacaosangue.tawk.help";
          }}
        />

        {/* Rotas privadas */}
        <AdminRoute exact path="/Agendas" component={Agendas} />
        <AdminRoute exact path="/Avisos" component={Avisos} />
        <AdminRoute exact path="/Doadores" component={Doadores} />
        <AdminRoute exact path="/Cancelamentos" component={Cancelamentos} />
        <AdminRoute exact path="/Graficos" component={Graficos} />
        <AdminRoute
          exact
          path="/AtualizarCadastro"
          component={AtualizarCadastro}
        />
        <AdminRoute
          exact
          path="/TodosGruposDoadores"
          component={TodosGruposDoadores}
        />

        <AdminRoute exact path="/PdfDoadores" component={PdfDoadores} />

        <AdminRoute exact path="/Admin@Sangue" component={Admin} />
        <AdminRoute exact path="/Admin@Chatbot" component={ChatBot} />

        <PrivateRoute exact path="/ConfirmData" component={ConfirmData} />
        <PrivateRoute exact path="/MeuAgendamento" component={MeuAgendamento} />
        <PrivateRoute
          exact
          path="/MeuGrupoDoadores"
          component={MeuGrupoDoadores}
        />
        <PrivateRoute exact path="/MinhasDoacoes" component={MinhasDoacoes} />
        <PublicRoute exact path="/ConfirmDoacao" component={ConfirmDoacao} />

        {/* Qualquer rota que esteja fora das estipuladas */}
        <Route path="*" component={() => <h1>Página não encontrada</h1>} />
      </Switch>
    </BrowserRouter>
  );
}
