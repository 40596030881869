import React from "react";
import { Box, Container, Copyright, Links, Text } from "./style";
import text from "../../Assets/json/text.json";

const Footer = () => {
  return (
    <Box>
      <Container>
        <Text>
          <div style={{ paddingBottom: "20px" }}>
            <h5 style={{ color: "white", fontWeight: "bold" }}>
              Doação de sangue
            </h5>
            <p>Doe sangue, Doe vida !</p>
          </div>
        </Text>
        <Links>
          <div style={{ flexDirection: "column", display: "flex" }}>
            <h5 style={{ color: "white", fontWeight: "bold" }}>Links</h5>
            <a
              href="https://www.indaiatuba.sp.gov.br/"
              target="_blank"
              style={{ textDecoration: "underline" }}
              rel="noreferrer"
            >
              Prefeitura de indaiatuba
            </a>
            <a
              href="https://www.fiec.com.br/site/index.do"
              target="_blank"
              style={{ textDecoration: "underline" }}
              rel="noreferrer"
            >
              FIEC
            </a>
            
          </div>
        </Links>
        <Copyright>Ultima atualização <span>{text.STATIC_ULTIMO_UPDATE}</span></Copyright>
        
      </Container>
    </Box>
  );
};
export default Footer;
