import { useState } from "react";
import {
  Background,
  Covid,
  Texto,
  Lista,
  ListaP,
  Lista2,
  Lista3,
  Tittle,
  Logos,
  Fiec,
} from "./style";
import logo2 from "../../Assets/Images/fiec.png";
import "./iframe.css";

const Info = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <Background>
      <Covid></Covid>
      <div className="container">
        {/* NAVBAR "IFRAMES" */}
        <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            Geral
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            Como ser um doador?
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            Quem não pode ser doador?
          </button>

          <button
            className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(4)}
          >
            Processo de triagem
          </button>

          <button
            className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(5)}
          >
            Perguntas frequentes
          </button>
          <button
            className={toggleState === 8 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(8)}
          >
            Medidas COVID-19
          </button>
        </div>
        {/* NAVBAR "IFRAMES" */}

        {/* CAMPO GERAL */}
        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <h2>Geral</h2>
            <hr />
            <Texto>
              <p style={{ textAlign: "justify", marginTop: "5vh" }}>
                A Campanha Mensal de Doação de Sangue, promovida pela Prefeitura
                de Indaiatuba através da Fundação Indaiatubana de Educação e
                Cultura - FIEC, em parceria com o Centro de Hematologia e
                Hemoterapia da Unicamp está agendada para os últimos sábados,
                exceto no mês 12, na unidade da FIEC I (Jd. Regina).
              </p>
            </Texto>
            <Logos>
              {/* <Indaituba>
                <img src={logo1} alt="Erro" />
              </Indaituba> */}
              <Fiec>
                {" "}
                <img src={logo2} alt="Erro" />
              </Fiec>
            </Logos>
          </div>
          {/* CAMPO GERAL */}

          {/* CAMPO COMO SER UM DOADOR? */}
          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <h2>Como ser um doador?</h2>
            <hr />
            <Texto>
              <p>
                A doação de sangue é um gesto solidário, ao realizar uma doação
                de sangue, você está ajudando até 4 pacientes que necessitam de
                transfusão.
              </p>
              <p>Importante!</p>

              <p>
                Para ser um doador é obrigatória a apresentação de documento
                oficial com foto. Ter entre 16 (dos 16 até 18 anos incompletos,
                apenas com consentimento formal dos responsáveis) e 67 anos, não
                estar em jejum e evitar apenas alimentos gordurosos nas quatro
                horas que antecedem a doação.
              </p>
              <p>Para ser um doador de sangue, basta:</p>
              <hr />
            </Texto>
            <Lista2>
              <ul>
                <li>Ser saudável;</li>
                <li>
                  Ter entre 18 e 69 anos; serão aceitos candidatos à doação de
                  sangue com idade de 16 e 17 anos, com a presença do
                  responsável;
                </li>
                <li>
                  Maiores de 60 anos não podem realizar a doação pela primeira
                  vez;
                </li>
                <li>Pesar mais de 50 kg;</li>
                <li>Ter dormido pelo menos 6 horas nas últimas 24 horas;</li>
                <li>
                  Estar alimentado. Não pode estar em jejum, fazer um lanche
                  leve, evitar alimentos gordurosos (ex. manteigas, bacon, ovos,
                  gordura animal, etc.) e refeições completas (almoço, jantar)
                  nas 4 horas que antecedem a doação;
                </li>
                <li>Não fumar 2 horas antes ou após a doação;</li>
                <li>
                  A frequência máxima é de quatro doações de sangue anuais para
                  o homem e de três doações de sangue anuais para as mulheres;
                </li>
                <li>
                  O intervalo mínimo entre uma doação de sangue e outra é de
                  dois meses para os homens e de três meses para as mulheres.
                </li>
              </ul>
            </Lista2>
          </div>
          {/* CAMPO COMO SER UM DOADOR? */}

          {/* CAMPO PARA QUEM NÃO PODE DOAR */}
          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <Tittle>
              <h2>Quem não pode ser doador</h2>
            </Tittle>
            <hr />

            <Texto>
              <p> Não pode doar sangue temporariamente, pessoas que:</p>
            </Texto>

            <Lista>
              <ul>
                <li>Tiveram gripe ou febre recentemente</li>

                <li>Receberam transfusão de sangue nos últimos 12 meses</li>

                <li>Vacinaram-se contra gripe há menos de 2 dias (48h)</li>

                <li>Encontram-se em tratamento odontológico</li>

                <li>Estejam fazendo regime para emagrecer</li>

                <li>
                  Vêm tomando medicamentos como antibiótico, anti-inflamatório,
                  AAS, propanolol, atenolol
                </li>

                <li>
                  Estiveram nos últimos seis meses em região que existe malária,
                  ou tiveram malária nos últimos três anos
                </li>

                <li>Tiverem feito endoscopia nos últimos 6 meses</li>

                <li>Passaram por cirurgia há menos de 6 meses</li>

                <li>Não tem parceiro fixo há pelo menos 6 meses</li>

                <li>São gestantes ou estão amamentando</li>

                <li>
                  Colocaram piercing, brinco ou fizeram tatuagem há menos de 1
                  ano
                </li>
                <Texto>
                  <p>Não podem doar sangue PERMANENTEMENTE, pessoas que:</p>
                </Texto>
                <hr />
                <Lista>
                  <li>
                    Portadores de Doença de Chagas e de outras doenças
                    transmitidas pelo sangue
                  </li>

                  <li>Alcoolistas</li>

                  <li>Quem faz tratamento para epilepsia (convulsão)</li>

                  <li>
                    Indivíduos que tiveram hepatite após os 10 anos de idade
                  </li>

                  <li>
                    Pessoas que possuem múltiplos parceiros sexuais, hábitos
                    promíscuos, usam drogas ou têm parceiros usuários de drogas
                    (situações de risco para HIV)
                  </li>

                  <li>Quem tem hipertireoidismo</li>

                  <li>Pessoas que passaram por tratamento de câncer</li>

                  <li>Diabéticos que usam insulina</li>

                  <li>
                    Transplantados (córnea, rim, pulmão, coração, medula e
                    outros)
                  </li>
                </Lista>
              </ul>
            </Lista>
          </div>

          {/* CAMPO PARA QUEM NÃO PODE DOAR */}

          {/* PROCESSO DE TRIAGEM */}
          <div
            className={
              toggleState === 4 ? "content  active-content" : "content"
            }
          >
            <h2>Processo de triagem</h2>
            <hr />
            <Texto>
              <p>
                Se você vai doar pela primeira vez, saiba como funciona o
                processo de triagem que envolvem as etapas da Doação:
              </p>
            </Texto>
            <ListaP>
              <ol>
                <li> Retirada da senha online </li>
                O candidato é recebido por um representante da FIEC, é
                importante que avise sobre a participação pela primeira vez como
                doador, para que seja explicado como acontece a campanha e
                informado os principais motivos que possam impedir a sua doação.
                <hr />
                <li>Sala de espera</li>
                Nessa etapa o candidato aguarda até que seja chamado para o
                cadastro, na sala de espera também são passadas algumas
                orientações.
                <hr />
                <li>Cadastro</li>
                Obrigatório a apresentação de um documento oficial com Foto para
                cadastro. Nessa etapa informe se tem interesse em se cadastar
                para ser doador de Medula Óssea.
                <hr />
                <li>Exame físico</li>
                São realizados testes para: anemia, verificação de pressão
                arterial, pulso, temperatura, peso e altura.
                <hr />
                <li>Entrevista</li>
                A entrevista é individual, são feitas perguntas específicas, e
                assinado um termo de responsabilidade quanto a veracidade de
                suas respostas
                <hr />
                <li>Coleta</li>
                Será coletado uma bolsa de sangue e amostras para os exames
                laboratoriais.
                <hr />
                <li>Lanche</li>
                Após a doação o candidato é encaminhado para um local onde é
                oferecido um lanche
                <hr />
                Todo o processo dura em média 2 (duas) horas, e conta com o
                apoio da Secretaria Municipal de Saúde, que disponibiliza uma
                ambulância durante todo o período de doação, seguindo normas da
                Unicamp (Universidade de Campinas).
              </ol>
            </ListaP>
          </div>
          {/* PROCESSO DE TRIAGEM */}

          <div
            className={
              toggleState === 5 ? "content  active-content" : "content"
            }
          >
            <h2>Perguntas Frequentes</h2>
            <hr />
            <ListaP>
              <ul>
                <li>Quem não pode ser um doador?</li>
                <hr />
                Não poderá ser doador o candidato que estiver fazendo algum tipo
                de tratamento, usando medicamento, estiver com gripe, ter tomado
                a vacina contra a febre amarela ou contra a gripe comum há menos
                de 30 dias, não tiver parceiro (a) fixo (a), pesar menos de 50
                quilos, tiver feito endoscopia há menos de seis meses, tiver
                piercing ou tatuagem há menos de um ano, for diabético, se tiver
                ingerido bebida alcoólica na noite anterior e ter fumado horas
                antes da doação.
                <li>Quais medicamentos impedem a doação?</li>
                <hr />
                Há uma grande variação de normas para medicamentos e a
                quantidade de medicamentos postos no mercado constantemente. O
                ideal é que o doador entre em contato com o Hemocentro
                0800-722-8432
                <li>É necessário estar em Jejum?</li>
                <hr />O candidato não pode estar em jejum! No entanto, ele não
                deve ter ingerido alimentos gordurosos (como “bacon”, ovos,
                feijoada, etc.) pelo menos 4 horas antes da doação.
                <li> Qual a frequência com que posso doar sangue?</li>
                <hr />O intervalo mínimo entre doações é de 60 dias para homens
                e 90 dias para mulheres. É importante ressaltar que os homens
                podem doar no máximo 4 vezes em 12 meses e as mulheres 3 vezes
                nesse mesmo período. Para pessoas com mais de 60 anos, o
                intervalo mínimo entre as doações é de 6 meses.
                <li>Quanto tempo leva o processo de doação?</li>
                <hr />
                Todo o processo da doação que é cadastro, pré-triagem, triagem e
                lanche, leva aproximadamente oitenta minutos, podendo ser maior
                ou menor dependendo da quantidade de pessoas que estão presentes
                no local.
                <li>
                  O que acontece se for encontrado algum exame laboratorial
                  alterado na doação?
                </li>
                <hr />
                Nesse caso, o candidato será convocado para comparecer ao
                Hemocentro para ser orientado sobre o significado desse exame e
                ser encaminhado para atendimento médico especializado quando for
                o caso.
                <li>Quanto sangue é retirado?</li>
                <hr />
                São 450 mililitros. Esse volume pode salvar a vida de até quatro
                pessoas.
              </ul>
            </ListaP>
          </div>

          <div
            className={
              toggleState === 6 ? "content  active-content" : "content"
            }
          >
            <h2>Cadastro</h2>
            <hr />
            <p style={{ textAlign: "center" }}>EM DESENVOLVIMENTO....</p>
          </div>
          <div
            className={
              toggleState === 8 ? "content  active-content" : "content"
            }
          >
            <h2>Medidas Covid-19</h2>
            <hr />
            <Texto>
              <p>
                O candidato que já tenha se vacinado contra a covid-19 fica
                impedido de doar sangue no prazo de 48 horas, para cada dose da
                CoronaVac, e de sete dias após cada dose das vacinas da
                AstraZeneca, Pfizer e Janssen. É necessário apresentar o
                comprovante de vacinação.
              </p>
              <p>Não poderá ser doador o candidato que:</p>
            </Texto>
            <hr />
            <Lista3>
              <ul>
                <li>
                  nos últimos 30 dias tenham retornado de viagem ao exterior;
                </li>
                <li>
                  tenha tido sintomas respiratórios, incluindo gripe, resfriado
                  ou febre;
                </li>
              </ul>
            </Lista3>
          </div>
        </div>
      </div>
    </Background>
  );
};
export default Info;
