import React from "react";
import { TableContainer, TableBody, TableHead } from "./styles";
import { useHistory } from "react-router-dom";

export default function Table({ data }) {
  const history = useHistory();

  return (
    <TableContainer>
      <TableHead>
        <tr>
          <td>NOME</td>
          <td>CPF</td>
          <td>Nasc</td>
          <td>E-mail</td>
          <td>Fone</td>
          <td>Logradouro</td>
          <td>Numero</td>
          <td>CEP</td>
          <td align="center">Ultima Doação</td>
        </tr>
      </TableHead>
      <TableBody>
        {data.map((d, i) => (
          <tr
            onClick={() => {
              history.push(`/AtualizarCadastro?cpf=${d.cpf}`);
            }}
            key={i}
          >
            <td data-title="NOME">{d.nome}</td>
            <td data-title="CPF">{d.cpf}</td>
            <td data-title="Data Nasc.">{d.data_nasc}</td>
            <td data-title="E-mail">{d.email}</td>
            <td data-title="Fone">{d.telefone}</td>
            <td data-title="Logradouro">{d.logradouro}</td>
            <td data-title="Numero">{d.numero}</td>
            <td data-title="CEP">{d.cep}</td>
            <td align="center" data-title="Ultima Doação">
              {d.ultima_doacao ? d.ultima_doacao : "-"}
            </td>
          </tr>
        ))}
      </TableBody>
    </TableContainer>
  );
}
