import styled from 'styled-components';

export const Container = styled.div`
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    margin: 1rem 0 1rem 0;
    border-radius: 2rem;
    border: 2px solid var(--red-color);

    small{
        opacity: 50%;
    }

    @media screen and (max-width: 610px) {
        display: grid;
        border: none;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        height: 84px;
        width: 100%;
    	grid-template-areas: "bl br"
                             "i i";
        justify-items: center;
    }
`;

export const ButtonLeft = styled.button`
    width: 250px;
    height: 34px;
    border-radius: 1rem;
    background-color: transparent;
    margin-left: 3px;
    transition: color 300ms linear;
    position: relative;
    left: -100px;
    color: white;
    grid-area: bl;
    color: white;

    @media screen and (max-width: 610px) {
        width: 80px;
        position: static;
        justify-self: right;
    }
`;

export const ButtonRight = styled.button`
    width: 250px;
    height: 34px;
    border-radius: 1rem;
    background-color: transparent;
    margin-left: 3px;
    transition: color 300ms linear;
    position: relative;
    left: -100px ;
    grid-area: br;

    @media screen and (max-width: 610px) {
        width: 80px;
        position: static;
        justify-self: left;
    }
`;

export const Selector = styled.div`
    background-color: var(--red-color);
    width: 250px;
    border-radius: 1rem;
    margin-left: 3px;
    height: 34px;
    transition: transform 700ms;
    color: transparent;
    grid-area: bl;

    @media screen and (max-width: 610px) {
        width: 80px;
        justify-self: right;
        margin: 0;
        z-index: -5;
    }
`;

export const Input = styled.input`
    text-align: left;
    width: 293px;
    height: 34px;
    border-radius: 1rem;
    border: none;
    margin-right: 3px;
    margin-left: -100px;
    padding-left: 15px;
    background-color: #f3f3f3;
    grid-area: i;
    
    @media screen and (max-width: 610px) {
        position: relative;
        margin-left: 0;
        margin: 0;
        width: 100%;
        justify-self: center;
        align-self: center;
    }
    `;

export const SerchArea = styled.div`
    width: 100%;
    position: relative;
    
    svg{
        background-color: #f3f3f3;
        position: absolute;
        top: 35%;
        right: 1%;
        @media screen and (max-width: 610px) {
            top: 30%;
        }
    }
    @media screen and (max-width: 610px) {
        grid-area: i;
    }
`;