/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Titulo,
  P,
  Logos,
  Fiec,
  Link,
  ConfirmCadastro,
  Doador,
  H2,
  ContainerWhatsApp,
} from "./style";
import fiec from "../../Assets/Images/fiec.png";
import Footer from "../../Components/Footer/Index";
import Modal from "react-modal";
import "./style.css";
import ChatBot from "../../Components/ChatBot";
import { useEffect, useState } from "react";
import WhatsApp from "../../Assets/Images/whatsapp.jpg";

Modal.setAppElement("#root");
const Home = () => {
  function abrirPDF() {
    window.open(
      "https://doacaosangue.fiecdev.com.br/Manualdeuso.pdf",
      "PDF",
      "height=780px  width=560px"
    );
  }

  useEffect(() => {
    if (!!localStorage.getItem("token")) {
      setlinkPara("/MeuAgendamento");
    } else {
      setlinkPara("/Login");
    }
  }, []);

  const [linkPara, setlinkPara] = useState("");

  return (
    <>
      <Titulo>Seja bem-vindo!</Titulo>
      <P>Este site é uma realização de:</P>

      <Logos>
        {/* <Indaituba>
          <img src={indaituba} alt="Error" />
        </Indaituba> */}
        <Fiec>
          <img src={fiec} alt="Error" />
        </Fiec>
      </Logos>

      <H2>O uso da máscara no local da doação é obrigatório!</H2>
      <H2>Leve a sua!</H2>

      <ContainerWhatsApp>
        <p>
          Receba mais informações sobre da Campanha de Doação de Sangue
          participando do nosso{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://chat.whatsapp.com/E1J5rn0l3PXIQfzpbFbSba"
          >
            Grupo de WhatsApp.
          </a>
        </p>
        <img src={WhatsApp} alt="Grupo do WhatsApp" />
      </ContainerWhatsApp>

      <ConfirmCadastro>
        <Link to={linkPara}>
          <a>Quero doar SANGUE!</a>
        </Link>

        <p
          className="msg"
          style={{ fontSize: 15, fontWeight: "bold", marginTop: 20 }}
        >
          <a
            className="msg"
            style={{ color: "black" }}
            href="https://www.fiec.com.br/site/noticia/2088/ver.do"
            target="_blank"
            rel="noreferrer"
          >
            Confira o calendário de doação
          </a>
        </p>
      </ConfirmCadastro>
      <Doador>
        <Link to={"#"} onClick={abrirPDF}>
          <a>Manual de Uso do site</a>
        </Link>
        <Link to={"/Info"}>
          <a>Informações para Doação </a>
        </Link>
      </Doador>
      <Footer />
      <ChatBot />
    </>
  );
};

export default Home;
