import styled from "styled-components";
import { Link } from 'react-router-dom';

export const Container = styled.div`
    width: 100%;
    /* height: 70vh; */
    height: 55vh;
    display: grid;
    justify-items: center;
    align-items: center;
`

export const AddAgenda = styled(Link)`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    /* padding: .625rem 1.25rem; */
    line-height: 1.5;
    border-radius: 10px;
    text-transform: uppercase;
    will-change: transform;
    letter-spacing: .025em;
    font-size: .875rem;
    color: #fff;
    border: none;
    background: var(--red-color);
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    transition: all .3s linear;
    text-decoration: none;
    padding: 10px;
    margin-top: 60px;
    margin-bottom: 50px;

    @media screen and (max-width: 768px){
        margin-top: 10%;
        margin-bottom: 30px;
    }

    &:hover{
        color: #fff;
        filter: brightness(90%);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
        transform: translateY(-1px);
    }

`;

export const Pagination = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    button{
        cursor: pointer;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        font-weight: 600;
        border: 1px solid transparent;
        padding: .625rem 1.25rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        text-transform: uppercase;
        will-change: transform;
        letter-spacing: .025em;
        font-size: .875rem;
        color: #fff;
        background-color: var(--red-color);
        border-color: var(--red-color);
        box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
        transition: all .3s linear;
        &:hover{
            filter: brightness(90%);
            box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
            transform: translateY(-1px);
        }
        &:disabled{
            opacity: 50%;
            cursor: not-allowed;
        }
        svg{
            vertical-align: middle;
            margin-left: 0.1rem;
        }
    }
    small{
        opacity: 50%;
    }
`;

export const BUTTON= styled.button`

`
