import React, { useState, useEffect } from "react";
import { Background, Content, Button, Form } from "./style";
import api, { baseURL } from "../../services/api";
import "./inputMask.css";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Auth from "../../services/auth";
import ModalPlanilha from "../../Components/Modal/Doadores";
import TableDoadores from "../../Components/Tables/TableDoadores";
import Pagination from "../../Components/Pagination";
import Switch from "../../Components/Switch";

const Doadores = () => {
  const history = useHistory();
  const [cpf, setCpf] = useState("");
  const [nome, setNome] = useState("");
  const [filtro] = useState("nome");
  const [showModalPlanilha, setShowModalPlanilha] = useState(false);
  const [data, setData] = useState([]);
  const [auth] = useState(Auth.auth());
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    api
      .get(`/admin/users/?nome=${nome}&cpf=${cpf}&filtro=true`)
      .then((response) => {
        setData(response.data.doadores);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          history.push({
            pathname: "/",
          });
        }
      });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nome, cpf]);

  useEffect(() => {
    if (selected === 0) {
      setCpf("");
      setNome(search);
    } else {
      setNome("");
      setCpf(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / 10));
  }, [data]);

  /**
   * Vai servir para gerar a planilha .xlsx do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();

    Swal.fire({
      title: "Escolha uma das opções",
      text: "Qual planilha deseja baixar?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonColor: "var(--red-color)",
      denyButtonColor: "var(--red-color)",
      confirmButtonText: "Histórico de doações",
      denyButtonText: "Todos os usuários",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Aguarde!",
          text: "O relatório está sendo gerado!",
          icon: "info",
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          await api
            .post(
              `/admin/generatexlsx/${
                filtro === "nome"
                  ? `?nome=${nome}&filtro=true`
                  : `${cpf}?filtro=true`
              }`
            )
            .then((response) => {
              if (response.data.error)
                return Swal.fire("Atenção", response.data.error, "warning");
              window.open(`${baseURL}/doadores?auth=${auth}`);
              Swal.close();
            });
        } catch (error) {
          alert(error);
        }
      } else if (result.isDenied) {
        Swal.fire({
          title: "Aguarde!",
          text: "O relatório está sendo gerado!",
          icon: "info",
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          api.post(`/admin/todosusuarios/`).then((response) => {
            if (response.data.error)
              return Swal.fire("Atenção", response.data.error, "warning");
            window.open(`${baseURL}/todosusuarios?auth=${auth}`);
            Swal.close();
          });
        } catch (error) {
          alert(error);
        }
      }
    });
  }

  return (
    <Background>
      <Content>
        <h1 style={{ fontWeight: "bolder" }}> Doadores Cadastrados </h1>
        <hr />
        <Form onSubmit={handleSubmit}>
          <Button>
            <button type="submit">Baixar Planilha</button>
          </Button>
        </Form>

        <Switch
          text1={"Nome"}
          text2={"CPF"}
          placeholder1={"Insira o nome"}
          placeholder2={"Insira o CPF"}
          search={search}
          selected={selected}
          setSelected={setSelected}
          setSearch={setSearch}
          cpf={"1"}
        />
        <TableDoadores data={data.slice((page - 1) * 10, page * 10)} />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Content>
      <ModalPlanilha
        showModal={showModalPlanilha}
        setShowModal={setShowModalPlanilha}
      />
    </Background>
  );
};

export default Doadores;
