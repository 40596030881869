import React from "react";
import { TableContainer, TableBody, TableHead } from "./styles";
import { IoEyeOutline, IoCreate, IoTrashOutline } from "react-icons/io5";

export default function Table({
  list,
  data,
  handleClicked,
  handleEdit,
  handleDelete,
}) {
  return (
    <TableContainer>
      <TableHead>
        <tr>
          {list.map((l, i) => (
            <th key={i} style={{ textAlign: "center" }}>
              {l.replace(/_/g, " ")}
            </th>
          ))}
          <th style={{ width: 120, textAlign: "center" }}>Relatório</th>
          <th style={{ width: 120, textAlign: "center" }}>Editar</th>
          <th style={{ width: 120, textAlign: "center" }}>Excluir</th>
        </tr>
      </TableHead>
      <TableBody>
        {data.map((d, i) => (
          <tr key={i}>
            <td data-title="Data Doação">{d.data_doacao}</td>
            <td data-title="Data Início">{d.data_inicio}</td>
            <td data-title="Data Término">{d.data_termino}</td>
            <td data-title="Relatório">
              <IoEyeOutline
                color="blue"
                onClick={() => {
                  handleClicked(d.id);
                }}
              />
            </td>
            <td
              style={
                d?.data_inicio <= new Date().toLocaleDateString() &&
                d?.data_termino >= new Date().toLocaleDateString()
                  ? { cursor: "not-allowed" }
                  : {}
              }
            >
              <IoCreate
                color="black"
                onClick={async () => {
                  await handleEdit(d);
                }}
              />
            </td>
            {d.vagas_preenchidas !== 0 ? (
              <td data-title="Excluir">
                <IoTrashOutline
                  color="red"
                  onClick={async () => {
                    await handleDelete(d.id);
                  }}
                />
              </td>
            ) : (
              <td data-title="Excluir">
                <IoTrashOutline
                  color="red"
                  style={{ cursor: "not-allowed", pointerEvents: "none" }}
                  onClick={async () => {
                    await handleDelete(d.id);
                  }}
                />
              </td>
            )}
          </tr>
        ))}
      </TableBody>
    </TableContainer>
  );
}
